/* square buttons */
.rec.rec-arrow {
    border-radius: 0;
    display: none;
}
/* round buttons on hover */
/* .rec.rec-arrow:hover {
    border-radius: 50%;
} */
/* hide disabled buttons */
.rec.rec-arrow:disabled {
    visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
    outline: none;
    margin: 0;
    padding: 0;
    box-shadow: inset 0 0 1px 1px lightgrey;
}

.rec-carousel-item {
    margin: 0px;
    padding: 0px;
    width: 100%;
}
.rec-carousel {
    margin: 0px;
    padding: 0px;
    width: 100%;
}